import { getBlobDownloader } from 'components/src/helpers/downloader';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import * as React from 'react';
import { statusRequestHook } from '../api/statusRequestHook';
import { AuthWrapper } from '../auth/AuthWrapper';
import { Header } from '../components/Header';
import { Img } from '../components/Img';
import { ProgressBar } from '../components/ProgressBar';

const imageSource = require('./../components/assets/undraw_processing_qj6a.png');

const ProgressPage = (props) => {
  const { jobId, fileName } = queryString.parse(props.location.search);

  const [progress, setProgress] = React.useState<number>(40);

  const { start } = statusRequestHook({
    filename: fileName as string ?? 'download',
    onDone(file: any, uploadedFilename) {
      const arr = new Uint8Array(file);
      const blob = new Blob([arr], { type: 'text/csv' });
      const filename = uploadedFilename ? `${uploadedFilename}.csv` : 'download.csv';

      getBlobDownloader(blob, filename).startDownload();
      setProgress(100);

      navigate(`/udało-się`);
    },
    onFailure(error: any) {
      navigate(`/nie-udało-się`);
    },
    onProgress(progress: number) {
      setProgress(progress);
    },
  });

  React.useEffect(() => {
    start(jobId as string);
  }, []);

  return (
    <>
      <ProgressBar
        progress={progress}
      />
      <Img src={imageSource}/>
    </>
  );
};

export default (props) => {
  return (
    <AuthWrapper>
      <Header>
        <span className="number">3.</span>
        Poczekaj aż skonwertujemy Twój plik
      </Header>
      <ProgressPage
        {...props}
      />
    </AuthWrapper>
  );
};
