import { apiRequest } from './apiRequest';
import { API_URL } from './request';
import * as React from 'react';

const STATUS_URL = `${API_URL}/status`;

type FileUploadHookParams = {
  onDone(file: any, filename?: string);
  onProgress(progress: number);
  onFailure(error: any);
  filename?: string;
};

export const statusRequestHook = ({ onDone, onProgress, onFailure, filename }: FileUploadHookParams) => {
  const [statusRequestIsSending, setIsSending] = React.useState(false);
  const [jobId, setJobId] = React.useState<string>(null);
  const timer = React.useRef<number>();

  const start = (jobId: string) => {
    setJobId(jobId);
  };
  const stop = () => {
    setJobId(null);
    window.clearInterval(timer.current);
  };

  const handleStatusRequestSend = React.useCallback(async (jobId: string) => {
    if (statusRequestIsSending) {
      return;
    }
    setIsSending(true);
    try {
      const response = await apiRequest(`${STATUS_URL}/${jobId}`, {
        method: 'GET',
      });
      if (response) {
        const { progress, data } = await response.json();
        if (Number.isInteger(progress)) {
          onProgress(progress);
        }
        if (data) {
          if (data?.type === 'Buffer' && data?.data) {
            onDone(data.data, filename);
            stop();
          }
        }
      }
    } catch (error) {
      onFailure({ error });
      stop();
    }
    setIsSending(false);
  }, [statusRequestIsSending, filename]);

  React.useEffect(() => {
    if (!jobId) {
      return;
    }
    timer.current = window.setInterval(async () => {
      await handleStatusRequestSend(jobId);
    }, 2000);
    return () => {
      window.clearInterval(timer.current);
    };
  }, [jobId]);

  return { start };
};
